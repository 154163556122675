export function hexToRgba(hex: string, opacity: string | number = 1) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let hexArray = hex.substring(1).split('')
    if (hexArray.length === 3) {
      hexArray = [
        hexArray[0],
        hexArray[0],
        hexArray[1],
        hexArray[1],
        hexArray[2],
        hexArray[2],
      ]
    }
    const hexNumber = Number(`0x${hexArray.join('')}`)
    return `rgba(${[
      (hexNumber >> 16) & 255,
      (hexNumber >> 8) & 255,
      hexNumber & 255,
    ].join(',')},${opacity})`
  }
  return 'rgba(0, 0, 0, 1)'
}
